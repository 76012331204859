<template>
  <div
      class="d-flex"
  >
    <v-spacer />
    <v-btn
        color="primary"
        class="my-3 hidden-xs-only"
        @click="clickCreateDocument"
    >
      <v-icon>mdi-plus</v-icon>
      Aggiungi documento
    </v-btn>
    <v-btn
        color="primary"
        class="my-3 hidden-sm-and-up"
        @click="clickCreateDocument"
        block
    >
      <v-icon>mdi-plus</v-icon>
      Aggiungi documento
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'user-documents-header',
  methods: {
    clickCreateDocument() {
      this.$emit('clickCreate')
    }
  }
}
</script>