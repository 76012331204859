<template>
  <div>
    <v-dialog
      v-model="is_show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Modifica Documento Condiviso</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="editDocument"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="localDocumentData.title"
                  label="Titolo"
                  outlined
                  required
                  dense
                  disabled
                  :rules="[formRules.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="localDocumentData.description"
                  label="Descrizione"
                  rows="3"
                  dense
                  outlined
                  disabled
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
              >
                <v-switch
                  v-model="localDocumentData.flag_auth_import"
                  label="Condividi automaticamente nelle nuove pratiche"
                  false-value="no"
                  true-value="yes"
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="editDocument"
                >
                  Salva
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'EditUserDocument',
  props: {
    is_show: {
      type: Boolean,
      default: false,
    },
    documentData: Object,
  },
  data() {
    return {
      form: {},
      rules: {
        required: value => !!value || 'Required.',
        emailRules: v => /.+@.+/.test(v) || 'E-mail must be valid',
        cfRule: v => /^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$/.test(v) || 'not valid',
      },
      localDocumentData: {},
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['formRules']),
  },
  watch: {
    is_show(newShow) {
      if (newShow) {
        this.localDocumentData = JSON.parse(JSON.stringify(this.documentData))
      }
    },
  },
  methods: {
    async editDocument() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.$api.editUserDocument(this.documentData.id, this.localDocumentData)
          this.$emit('reload-data')
          await this.$store.dispatch('set_alert', { msg: 'Documento Modificato con successo', type: 'success' })
          this.closeModal()
        } catch (e) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    closeModal() {
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
