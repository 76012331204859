<template>
  <v-card
      class="mt-3"
  >
    <v-card-title>
      <h5>{{ documentObj.title }}</h5>
      <v-spacer></v-spacer>
      <user-documents-menu
        @clickEdit="is_show.edit = true"
        @clickDelete="is_show.delete = true"
      />
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <div v-html="documentObj.description"></div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <template
          v-if="documentObj.flag_auth_import === 'yes'"
      >
        <v-tooltip
            top
        >
          <template
              v-slot:activator="{ on, attrs }"
          >
            <v-chip
                v-bind="attrs"
                v-on="on"
                class="ma-2 white--text success"
            >
              Condivisione automatica
            </v-chip>
          </template>
          Questo documento sarà automaticamente trasferito al momento dell'accettazione della pratica
        </v-tooltip>
      </template>
      <v-spacer></v-spacer>
      <user-documents-action-menu
        :documentObj="documentObj"
        @clickUpload="is_show.upload = true"
      />
    </v-card-actions>

    <edit-user-document :is_show="is_show.edit" @close="is_show.edit=false" :documentData="documentObj" v-on:reload-data="getData" />
    <destroy-user-document :is_show="is_show.delete" @close="is_show.delete=false" :documentData="documentObj" v-on:reload-data="getDataAll" />
    <upload-file :is_show="is_show.upload" @close="is_show.upload=false" :upload_url="uploadUrl" @reload-data="getData" />
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import UserDocumentsMenu from "@/components/menus/user-documents-menu";
import EditUserDocument from "@/components/modals/edit-user-document"
import DestroyUserDocument from "@/components/modals/delete-user-document"
import UploadFile from "@/components/modals/upload-file"
import UserDocumentsActionMenu from "@/components/menus/user-documents-action-menu";

export default {
  name: "user-documents-card",
  components: {
    UserDocumentsActionMenu,
    UserDocumentsMenu,
    EditUserDocument,
    DestroyUserDocument,
    UploadFile
  },
  props: {
    documentObj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      is_show: {
        edit: false,
        delete: false,
        upload: false
      },
    }
  },
  computed: {
    ...mapGetters(['loggedUser']),
    uploadUrl(){
      return this.$apiUrl+'user-documents/'+this.loggedUser.id+'/documents/'+this.documentObj.id
    }
  },
  methods: {
    async getData(){
      await this.$store.dispatch('getUserDocument',this.documentObj.id)
    },
    async getDataAll(){
      this.$emit('reloadAllData')
    }
  }
}
</script>