<template>
    <div>
        <user-documents-header
          @clickCreate="is_show.create = true"
        />
        <user-card
          sectionName="Documenti condivisi"
        />
        <v-divider></v-divider>

        <v-row>
            <v-col
                cols="12"
                sm="12"
                md="4"
                v-for="(doc,idx) in userDocumentsList"
                :key="idx"
            >
              <user-documents-card
                :documentObj="doc"
                @clickDelete="is_show.delete = true"
                @reloadAllData="getData"
              />
            </v-col>
        </v-row>
        <create-user-document :is_show="is_show.create" @close="is_show.create=false" v-on:reload-data="getData" />
    </div>
</template>

<script>
import CreateUserDocument from "@/components/modals/create-user-document"

import {mapGetters} from 'vuex'
import UserDocumentsHeader from "@/components/section-headers/user-documents-header";
import UserCard from "@/components/cards/user-card";
import UserDocumentsCard from "@/components/cards/user-documents-card";

export default {
    components: {
      UserDocumentsCard,
      UserCard,
      UserDocumentsHeader,
      CreateUserDocument
    },
    data() {
        return {
            is_show: {
              create: false
            },
            upload_url: "",
            breadCrumb: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                },
                {
                    text: 'Documenti condivisi',
                    to: '/user-documents',
                }
            ]
        }
    },
    mounted() {
        this.$store.dispatch('setBreadCrumbLevels',this.breadCrumb)
    },
    computed: {
        ...mapGetters(['loggedUser','userDocumentsList'])
    },
    methods: {
        async getData(){
            await this.$store.dispatch('getUserDocuments')
        },
        setModalDocument(doc_id){
            this.upload_url = this.$apiUrl+'user-documents/'+this.loggedUser.id+'/documents/'+doc_id
        },
        async setModalDocumentObj(docObj){
            this.edit_doc_obj = await docObj;
        },
    }
}
</script>

<style scoped>

</style>