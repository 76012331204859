<template>
  <v-card className="pa-2">
    <v-card-title className="subheading font-weight-bold info--text">
      <span>{{ sectionName }} di {{ loggedUser.name }}</span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-actions>

    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'user-card',
  props: {
    sectionName: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['loggedUser'])
  }
}

</script>