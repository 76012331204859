import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [[_c(VChip,_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":(_vm.documentObj.documentFiles_count > 0) ? 'green' : 'grey',"text-color":"white"},on:{"dblclick":function($event){return _vm.goToDocumentDetail(_vm.documentObj.id)}}},'v-chip',attrs,false),on),[_c(VAvatar,{class:(_vm.documentObj.documentFiles_count > 0) ? 'green darken-4' : 'grey darken-4',attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.documentObj.documentFiles_count)+" ")]),_vm._v(" Gestisci ")],1)]]}}])},[_c(VList,[_c(VListItem,{attrs:{"link":"","to":{name:'userDocumentFiles',params:{did:_vm.documentObj.id}}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(" mdi-folder-open-outline ")]),_vm._v(" Apri ")],1)],1),(_vm.loggedUser.role === 'user')?_c(VListItem,{attrs:{"link":""},on:{"click":_vm.clickUpload}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(" mdi-upload ")]),_vm._v(" Carica file ")],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }