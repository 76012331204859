import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-3"},[_c(VCardTitle,[_c('h5',[_vm._v(_vm._s(_vm.documentObj.title))]),_c(VSpacer),_c('user-documents-menu',{on:{"clickEdit":function($event){_vm.is_show.edit = true},"clickDelete":function($event){_vm.is_show.delete = true}}})],1),_c(VCardText,[_c(VRow,[_c(VCol,[_c('div',{domProps:{"innerHTML":_vm._s(_vm.documentObj.description)}})])],1)],1),_c(VCardActions,[(_vm.documentObj.flag_auth_import === 'yes')?[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"ma-2 white--text success"},'v-chip',attrs,false),on),[_vm._v(" Condivisione automatica ")])]}}],null,false,2689680971)},[_vm._v(" Questo documento sarà automaticamente trasferito al momento dell'accettazione della pratica ")])]:_vm._e(),_c(VSpacer),_c('user-documents-action-menu',{attrs:{"documentObj":_vm.documentObj},on:{"clickUpload":function($event){_vm.is_show.upload = true}}})],2),_c('edit-user-document',{attrs:{"is_show":_vm.is_show.edit,"documentData":_vm.documentObj},on:{"close":function($event){_vm.is_show.edit=false},"reload-data":_vm.getData}}),_c('destroy-user-document',{attrs:{"is_show":_vm.is_show.delete,"documentData":_vm.documentObj},on:{"close":function($event){_vm.is_show.delete=false},"reload-data":_vm.getDataAll}}),_c('upload-file',{attrs:{"is_show":_vm.is_show.upload,"upload_url":_vm.uploadUrl},on:{"close":function($event){_vm.is_show.upload=false},"reload-data":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }