<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <template>
        <v-chip
            @dblclick="goToDocumentDetail(documentObj.id)"
            class="mb-2"
            :color="(documentObj.documentFiles_count > 0) ? 'green' : 'grey'"
            text-color="white"
            v-bind="attrs"
            v-on="on"
        >
          <v-avatar
              left
              :class="(documentObj.documentFiles_count > 0) ? 'green darken-4' : 'grey darken-4'"
          >
            {{ documentObj.documentFiles_count }}
          </v-avatar>
          Gestisci
        </v-chip>
      </template>
    </template>
    <v-list>
      <v-list-item
          link
          :to="{name:'userDocumentFiles',params:{did:documentObj.id}}"
      >
        <v-list-item-title>
          <v-icon
              small
              left
          >
            mdi-folder-open-outline
          </v-icon>
          Apri
        </v-list-item-title>
      </v-list-item>
      <v-list-item
          link
          v-if="loggedUser.role === 'user'"
          @click="clickUpload"
      >
        <v-list-item-title>
          <v-icon
              small
              left
          >
            mdi-upload
          </v-icon>
          Carica file
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "user-documents-action-menu",
  props: {
    documentObj: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['loggedUser'])
  },
  methods: {
    clickUpload() {
      this.$emit('clickUpload')
    },
    goToDocumentDetail(){
      this.$router.push({name:'userDocumentFiles',params:{did:this.documentObj.id}})
    }
  }
}
</script>