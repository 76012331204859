import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.is_show),callback:function ($$v) {_vm.is_show=$$v},expression:"is_show"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Aggiungi un documento condiviso")])]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.codeOpts,"label":"Tipo documento","outlined":"","required":""},model:{value:(_vm.form.default_document_id),callback:function ($$v) {_vm.$set(_vm.form, "default_document_id", $$v)},expression:"form.default_document_id"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VMenu,{ref:"menu_date",attrs:{"close-on-content-click":false,"return-value":_vm.menu_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.menu_date=$event},"update:return-value":function($event){_vm.menu_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Scadenza documento","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.form.expiry),callback:function ($$v) {_vm.$set(_vm.form, "expiry", $$v)},expression:"form.expiry"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_date),callback:function ($$v) {_vm.menu_date=$$v},expression:"menu_date"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","locale":"it-IT","first-day-of-week":1},model:{value:(_vm.form.expiry),callback:function ($$v) {_vm.$set(_vm.form, "expiry", $$v)},expression:"form.expiry"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu_date = false}}},[_vm._v(" Chiudi ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu_date.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VSwitch,{attrs:{"label":"Condividi automaticamente nelle nuove pratiche","false-value":"no","true-value":"yes"},model:{value:(_vm.form.flag_auth_import),callback:function ($$v) {_vm.$set(_vm.form, "flag_auth_import", $$v)},expression:"form.flag_auth_import"}})],1),_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading},on:{"click":_vm.closeModal}},[_vm._v(" Chiudi ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_vm._v(" Crea ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }