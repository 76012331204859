<template>
  <div>
    <v-dialog
      v-model="is_show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Aggiungi un documento condiviso</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="create"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-select
                  v-model="form.default_document_id"
                  :items="codeOpts"
                  label="Tipo documento"
                  outlined
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
              >
                <v-menu
                  ref="menu_date"
                  v-model="menu_date"
                  :close-on-content-click="false"
                  :return-value.sync="menu_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.expiry"
                      label="Scadenza documento"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.expiry"
                    no-title
                    scrollable
                    locale="it-IT"
                    :first-day-of-week="1"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu_date = false"
                    >
                      Chiudi
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu_date.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
              >
                <v-switch
                  v-model="form.flag_auth_import"
                  label="Condividi automaticamente nelle nuove pratiche"
                  false-value="no"
                  true-value="yes"
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="create"
                >
                  Crea
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'DocumentCreate',
  computed: {
    ...mapGetters(['defaultDocumentsList']),
    codeOpts() {
      const opts = []
      this.defaultDocumentsList.forEach(v => {
        opts.push({ text: v.title, value: v.id })
      })

      return opts
    },
  },
  props: {
    is_show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      loading: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu_date: false,
    }
  },
  methods: {
    async create() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.$api.createDocUser(this.form)
          this.$store.dispatch('set_alert', { msg: 'Documento creato con successo', type: 'success' })
          this.$emit('reload-data')
          this.closeModal()
        } catch (e) {
          this.loading = false
          this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    closeModal() {
      this.$refs.form.reset()
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
